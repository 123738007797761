import React, { useState, useEffect } from "react"
import {
  Container,
  Grid,
  Card,
  CardContent,
  CardActions,
  Typography,
  Button,
  useTheme,
  alpha,
  Fade,
  Box,
} from "@mui/material"
import { Link as RouterLink } from "react-router-dom"
import { keyframes } from "@emotion/react"
import { useTranslation } from "react-i18next"
import ArticleIcon from "@mui/icons-material/Article"
import CalendarTodayIcon from "@mui/icons-material/CalendarToday"
import PersonIcon from "@mui/icons-material/Person"

const floatAnim = keyframes`
  0% { transform: translateY(0px); }
  50% { transform: translateY(-15px); }
  100% { transform: translateY(0px); }
`

const Blog = () => {
  const theme = useTheme()
  const { t, i18n } = useTranslation("blog") // 🔹 Translation support
  const [posts, setPosts] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/api/blog/?lang=${i18n.language}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch blog posts.")
        }
        return response.json()
      })
      .then((data) => {
        setPosts(data)
        setLoading(false)
      })
      .catch((error) => {
        console.error("Error fetching blog posts:", error)
        setError(error.message)
        setLoading(false)
      })
  }, [i18n.language]) // 🔹 Re-fetch when language changes

  return (
    <Container maxWidth="lg" sx={{ py: 8 }}>
      <Fade in timeout={800}>
        <Box sx={{ textAlign: "center", mb: 8 }}>
          <Typography
            variant="h2"
            sx={{
              ...theme.mixins.gradientText,
              fontWeight: 800,
              mb: 2,
              fontSize: { xs: "2.5rem", md: "3.5rem" },
            }}
          >
            {t("title")}
          </Typography>
          <Typography
            variant="h6"
            sx={{
              color: "text.secondary",
              maxWidth: 600,
              mx: "auto",
            }}
          >
            {t("subtitle")}
          </Typography>
        </Box>
      </Fade>

      {loading ? (
        <Typography variant="body1" sx={{ textAlign: "center", width: "100%" }}>
          {t("loading")}
        </Typography>
      ) : error ? (
        <Typography variant="h5" color="error" sx={{ textAlign: "center" }}>
          {t("error")} {error}
        </Typography>
      ) : posts.length === 0 ? (
        <Typography variant="body1" sx={{ textAlign: "center", width: "100%" }}>
          {t("no_posts")}
        </Typography>
      ) : (
        <Grid container spacing={6}>
          {posts.map((post, index) => {
            const excerpt =
              post.content.length > 100
                ? `${post.content.substring(0, 100)}...`
                : post.content
            const date = new Date(post.created_at).toLocaleDateString()
            return (
              <Grid item xs={12} md={6} key={post.id}>
                <Fade
                  in
                  timeout={800}
                  style={{ transitionDelay: `${index * 100}ms` }}
                >
                  <Card
                    sx={{
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      borderRadius: "20px",
                      background: theme.palette.background.paper,
                      border: `1px solid ${theme.palette.divider}`,
                      transition: "transform 0.3s ease, box-shadow 0.3s ease",
                      "&:hover": {
                        transform: "translateY(-4px)",
                        boxShadow: theme.shadows[4],
                      },
                    }}
                  >
                    <Box
                      sx={{
                        height: 200,
                        bgcolor: alpha(theme.palette.primary.main, 0.1),
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        position: "relative",
                        overflow: "hidden",
                        "&::before": {
                          content: '""',
                          position: "absolute",
                          width: "200%",
                          height: "200%",
                          background: `conic-gradient(${alpha(
                            theme.palette.primary.main,
                            0.2
                          )} 20deg, transparent 120deg)`,
                          animation: `${floatAnim} 12s linear infinite`,
                        },
                      }}
                    >
                      <ArticleIcon
                        sx={{
                          fontSize: 60,
                          color: theme.palette.primary.main,
                          position: "relative",
                          zIndex: 1,
                        }}
                      />
                    </Box>

                    <CardContent sx={{ flexGrow: 1 }}>
                      <Typography
                        variant="h5"
                        sx={{ fontWeight: 700, mb: 2, lineHeight: 1.3 }}
                      >
                        {post.title}
                      </Typography>

                      <Typography
                        variant="body1"
                        sx={{ color: "text.secondary", mb: 3, lineHeight: 1.6 }}
                      >
                        {excerpt}
                      </Typography>

                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 3,
                          color: "text.secondary",
                        }}
                      >
                        <Box
                          sx={{ display: "flex", alignItems: "center", gap: 1 }}
                        >
                          <PersonIcon fontSize="small" />
                          <Typography variant="caption">
                            {t("author")}:{" "}
                            {post.author?.username
                              ? post.author.username.charAt(0).toUpperCase() +
                                post.author.username.slice(1)
                              : t("unknown")}
                          </Typography>
                        </Box>
                        <Box
                          sx={{ display: "flex", alignItems: "center", gap: 1 }}
                        >
                          <CalendarTodayIcon fontSize="small" />
                          <Typography variant="caption">{date}</Typography>
                        </Box>
                      </Box>
                    </CardContent>

                    <CardActions sx={{ px: 3, pb: 3 }}>
                      <Button
                        component={RouterLink}
                        to={`/blog/${post.slug}`}
                        variant="contained"
                        sx={{
                          px: 4,
                          borderRadius: theme.shape.buttonRadius,
                          background: `linear-gradient(45deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`,
                          "&:hover": {
                            transform: "translateY(-2px)",
                            boxShadow: `0 8px 24px ${alpha(
                              theme.palette.secondary.main,
                              0.3
                            )}`,
                          },
                          transition: "all 0.3s ease",
                        }}
                      >
                        {t("read_more")}
                      </Button>
                    </CardActions>
                  </Card>
                </Fade>
              </Grid>
            )
          })}
        </Grid>
      )}
    </Container>
  )
}

export default Blog
