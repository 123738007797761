import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  useTheme,
  alpha,
  Grid,
  Container,
  Fade,
  CircularProgress,
} from "@mui/material";
import { keyframes } from "@emotion/react";
import { useTranslation } from "react-i18next";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import EmailIcon from "@mui/icons-material/Email";

const floatAnim = keyframes`
  0% { transform: translateY(0px); }
  50% { transform: translateY(-15px); }
  100% { transform: translateY(0px); }
`;

const NewsletterForm = () => {
  const theme = useTheme();
  const { t } = useTranslation("newsletterForm");

  const [email, setEmail] = useState("");
  const [subscribed, setSubscribed] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubscribe = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await fetch("http://your-backend.com/api/newsletter/subscribe/", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        setSubscribed(true);
      } else {
        console.error("Subscription failed");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        py: 10,
        background: `linear-gradient(150deg, ${alpha(
          theme.palette.background.default,
          0.9
        )} 0%, ${alpha(theme.palette.background.paper, 0.95)} 100%)`,
        position: "relative",
        overflow: "hidden",
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={10} alignItems="center">
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                position: "relative",
                "&::before": {
                  content: '""',
                  position: "absolute",
                  width: "200%",
                  height: "200%",
                  background: `radial-gradient(${alpha(
                    theme.palette.primary.main,
                    0.1
                  )} 20%, transparent 60%)`,
                  animation: `${floatAnim} 8s linear infinite`,
                },
              }}
            >
              <Fade in timeout={800}>
                <Box sx={{ position: "relative" }}>
                  <Typography
                    variant="h2"
                    sx={{
                      textAlign: "center",
                      mb: 8,
                      ...theme.mixins.gradientText,
                      fontWeight: 800,
                    }}
                  >
                    {t("title")}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      color: "text.secondary",
                      fontSize: "1.1rem",
                      lineHeight: 1.8,
                      mb: 4,
                    }}
                  >
                    {t("description")}
                  </Typography>
                  <Box sx={{ display: "flex", gap: 3, alignItems: "center" }}>
                    <Button
                      variant="outlined"
                      href="https://github.com/amareteklay"
                      target="_blank"
                      sx={{
                        px: 4,
                        borderWidth: 2,
                        "&:hover": {
                          borderWidth: 2,
                          backgroundColor: alpha(
                            theme.palette.primary.main,
                            0.05
                          ),
                        },
                      }}
                    >
                      {t("github_button")}
                    </Button>
                    <Typography variant="body2" sx={{ color: "text.secondary" }}>
                      {t("email_text")}
                      <Box
                        component="span"
                        sx={{ color: "primary.main", ml: 1, fontWeight: 600 }}
                      >
                        amareteklay@gmail.com
                      </Box>
                    </Typography>
                  </Box>
                </Box>
              </Fade>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Fade in timeout={800} style={{ transitionDelay: "200ms" }}>
              <Box
                sx={{
                  p: 4,
                  borderRadius: `${theme.shape.cardRadius}px`,
                  background: theme.palette.background.paper,
                  boxShadow: theme.shadows[4],
                  position: "relative",
                }}
              >
                {subscribed ? (
                  <Box sx={{ textAlign: "center", py: 6 }}>
                    <CheckCircleIcon
                      sx={{
                        fontSize: 80,
                        color: theme.palette.success.main,
                        mb: 3,
                      }}
                    />
                    <Typography variant="h5" sx={{ mb: 2 }}>
                      {t("success.title")}
                    </Typography>
                    <Typography variant="body1" sx={{ color: "text.secondary" }}>
                      {t("success.message")}
                    </Typography>
                  </Box>
                ) : (
                  <form onSubmit={handleSubscribe}>
                    <TextField
                      fullWidth
                      label={t("form.email")}
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                      sx={{ mb: 3 }}
                    />
                    <Button
                      type="submit"
                      variant="contained"
                      disabled={loading}
                      fullWidth
                    >
                      {loading ? (
                        <CircularProgress size={24} sx={{ color: "white" }} />
                      ) : (
                        t("form.subscribe_button")
                      )}
                      <EmailIcon sx={{ ml: 1.5, fontSize: 20 }} />
                    </Button>
                  </form>
                )}
              </Box>
            </Fade>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default NewsletterForm;
