// src/theme/theme.js
import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { alpha } from '@mui/material';

const getDesignTokens = (mode) => {
  const palette = {
    mode,
    tonalOffset: 0.2,
    contrastThreshold: 4.5,
    divider:
      mode === 'light'
        ? alpha('#2A3447', 0.12)
        : alpha('#C9D1D9', 0.12),
    ...(mode === 'light'
      ? {
          // Light mode palette
          primary: { main: '#2A3447', contrastText: '#F5F7FA' },
          secondary: { main: '#E67E22', contrastText: '#2A3447' },
          error: { main: '#D94545' },
          warning: { main: '#E8B15D' },
          info: { main: '#3D8FAA' },
          success: { main: '#4CAF7D' },
          background: {
            default: '#F8FAFD',
            paper: '#FFFFFF',
          },
          text: {
            primary: alpha('#2A3447', 0.95),
            secondary: alpha('#2A3447', 0.7),
            disabled: alpha('#2A3447', 0.4),
          },
        }
      : {
          // Dark mode palette
          primary: { main: '#C9D1D9', contrastText: '#0D1117' },
          secondary: { main: '#E67E22', contrastText: '#0D1117' },
          error: { main: '#F07171' },
          warning: { main: '#FFB45B' },
          info: { main: '#6CB6FF' },
          success: { main: '#85D796' },
          background: {
            default: '#0D1117',
            paper: '#161B22',
          },
          text: {
            primary: alpha('#C9D1D9', 0.95),
            secondary: alpha('#C9D1D9', 0.7),
            disabled: alpha('#C9D1D9', 0.4),
          },
        }),
  };

  return {
    palette,
    typography: {
      fontFamily: 'Inter, sans-serif',
      h1: { fontWeight: 800, lineHeight: 1.2, letterSpacing: '-0.05em' },
      h2: { fontWeight: 700, lineHeight: 1.25, letterSpacing: '-0.025em' },
      h3: { fontWeight: 600, lineHeight: 1.3 },
      body1: { lineHeight: 1.6 },
      button: { fontWeight: 600, textTransform: 'none' },
    },
    shape: {
      borderRadius: 8,
      cardRadius: 12,
      buttonRadius: 6,
    },
    shadows:
      mode === 'light'
        ? [
            'none',
            '0 1px 2px rgba(0,0,0,0.05)',
            '0 2px 4px rgba(0,0,0,0.08)',
            '0 4px 8px rgba(0,0,0,0.1)',
            '0 8px 16px rgba(0,0,0,0.12)',
            ...Array(20).fill('none'),
          ]
        : [
            'none',
            '0 1px 2px rgba(255,255,255,0.05)',
            '0 2px 4px rgba(255,255,255,0.08)',
            '0 4px 8px rgba(255,255,255,0.1)',
            '0 8px 16px rgba(255,255,255,0.12)',
            ...Array(20).fill('none'),
          ],
    components: {
      MuiAppBar: {
        styleOverrides: {
          root: {
            backdropFilter: 'blur(8px)',
            backgroundColor: alpha(palette.background.paper, 0.8),
            borderBottom: `1px solid ${palette.divider}`,
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: 12,
            boxShadow: 2,
            border: `1px solid ${palette.divider}`,
            transition: 'transform 0.3s ease',
            '&:hover': {
              transform: 'translateY(-2px)',
              boxShadow: 4,
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            padding: '8px 20px',
            transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
            '&:hover': { transform: 'translateY(-1px)' },
          },
          contained: {
            boxShadow: 2,
            '&:hover': { boxShadow: 3 },
          },
        },
      },
    },
    mixins: {
      gradientText: {
        background: `linear-gradient(45deg, ${palette.primary.main} 0%, ${palette.secondary.main} 100%)`,
        color: palette.primary.main,
        display: 'inline-block',
        backgroundClip: 'text',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
      },
      glassEffect: {
        backdropFilter: 'blur(12px)',
        backgroundColor: alpha(palette.background.paper, 0.7),
        border: `1px solid ${alpha(palette.divider, 0.2)}`,
      },
    },
  };
};

const getTheme = (mode) => {
  // Create the base theme
  let theme = createTheme(getDesignTokens(mode));
  // Enhance it with responsive typography
  theme = responsiveFontSizes(theme);
  return theme;
};

export default getTheme;
