import React from "react";
import { Box, Typography, Button, Stack, useTheme, Fade } from "@mui/material";
import { alpha } from "@mui/material/styles";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Hero = () => {
  const { t } = useTranslation(["hero", "common"]);
  const theme = useTheme();

  return (
    <Box sx={{
      minHeight: "100vh",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      background: theme.palette.background.default,
      position: "relative",
      overflow: "hidden",
    }}>
      <Fade in timeout={800}>
        <Box sx={{ 
          position: "relative", 
          zIndex: 2, 
          textAlign: "center", 
          maxWidth: 1200, 
          width: "100%",
          p: 4,
        }}>
          <Typography variant="h1" sx={{
            fontWeight: 700,
            lineHeight: 1.2,
            mb: 2,
            color: "text.primary",
            fontSize: { xs: "2.5rem", md: "4rem" },
            letterSpacing: "-0.05em",
          }}>
            {t("title", { ns: "hero" })}
          </Typography>

          <Typography variant="h4" component="h2" sx={{
            fontWeight: 400,
            color: "text.secondary",
            maxWidth: 600,
            mx: "auto",
            mb: 4,
            fontSize: { xs: "1.25rem", md: "1.5rem" },
          }}>
            {t("name", { ns: "common" })}
          </Typography>

          <Stack direction={{ xs: "column", sm: "row" }} spacing={2} sx={{ 
            justifyContent: "center",
            '& .MuiButton-root': {
              minWidth: 200,
              fontSize: "1.1rem"
            }
          }}>
            <Button 
              component={RouterLink} 
              to="/portfolio" 
              variant="contained"
              size="large"
              sx={{
                borderRadius: "8px",
                bgcolor: "primary.main",
                '&:hover': {
                  bgcolor: "primary.dark",
                  transform: "translateY(-2px)",
                },
              }}
            >
              {t("btnSee", { ns: "hero" })}
            </Button>

            <Button 
              component={RouterLink} 
              to="/blog" 
              variant="outlined"
              size="large"
              sx={{
                borderRadius: "8px",
                borderWidth: 2,
                color: "text.primary",
                borderColor: "divider",
                '&:hover': {
                  borderColor: "primary.main",
                  color: "primary.main",
                  borderWidth: 2,
                },
              }}
            >
              {t("btnExplore", { ns: "hero" })}
            </Button>
          </Stack>
        </Box>
      </Fade>
    </Box>
  );
};

export default Hero;