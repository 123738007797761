import React from "react";
import {
  Box,
  Container,
  Typography,
  Link,
  IconButton,
  useTheme,
  Fade,
  Fab,
  useScrollTrigger,
  Zoom,
} from "@mui/material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";
import EmailIcon from "@mui/icons-material/Email";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { keyframes } from "@emotion/react";
import { useTranslation } from "react-i18next";

const wave = keyframes`
  0% { transform: rotate(0deg); }
  10% { transform: rotate(14deg); }
  20% { transform: rotate(-8deg); }
  30% { transform: rotate(14deg); }
  40% { transform: rotate(-4deg); }
  50% { transform: rotate(10deg); }
  60% { transform: rotate(0deg); }
  100% { transform: rotate(0deg); }
`;

const ScrollTop = ({ children }) => {
  const trigger = useScrollTrigger({ threshold: 100, disableHysteresis: true });

  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <Zoom in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{
          position: "fixed",
          bottom: 16,
          right: 16,
          zIndex: 1000,
        }}
      >
        {children}
      </Box>
    </Zoom>
  );
};

const Footer = () => {
  const { t } = useTranslation("footer"); // Load footer translations
  const theme = useTheme();

  return (
    <Fade in timeout={800}>
      <Box
        component="footer"
        sx={{
          backgroundColor: "background.paper",
          color: "text.primary",
          py: 6,
          mt: "auto",
          boxShadow:
            theme.palette.mode === "dark"
              ? "0 -4px 24px rgba(255,255,255,0.05)"
              : "0 -4px 24px rgba(0,0,0,0.05)",
          position: "relative",
          "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            height: "2px",
            background: `linear-gradient(90deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`,
          },
        }}
      >
        <ScrollTop>
          <Fab
            size="medium"
            aria-label="scroll back to top"
            sx={{
              background: `linear-gradient(45deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`,
              color: theme.palette.primary.contrastText,
              "&:hover": {
                transform: "scale(1.1)",
                boxShadow: theme.shadows[6],
              },
              transition: "all 0.3s ease",
            }}
          >
            <KeyboardArrowUpIcon />
          </Fab>
        </ScrollTop>

        <Container maxWidth="lg">
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 2.5,
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontWeight: 600,
                display: "flex",
                alignItems: "center",
                gap: 1,
                "&:hover > span": {
                  animation: `${wave} 1.8s ease-in-out`,
                },
              }}
            >
              {t("letsConnect")}
              <span role="img" aria-label="wave">
                👋
              </span>
            </Typography>

            {/* Social Links */}
            <Box
              sx={{
                display: "flex",
                gap: 3,
                mb: 3,
                "& .MuiIconButton-root": {
                  transition: "transform 0.3s ease, color 0.3s ease",
                  "&:hover": {
                    transform: "translateY(-3px)",
                    color: theme.palette.primary.main,
                    backgroundColor: "transparent",
                  },
                },
              }}
            >
              <IconButton
                component={Link}
                href="https://www.linkedin.com/in/amareteklay"
                target="_blank"
                rel="noopener"
                aria-label={t("linkedin")}
                size="large"
              >
                <LinkedInIcon fontSize="medium" />
              </IconButton>
              <IconButton
                component={Link}
                href="https://github.com/Amareteklay"
                target="_blank"
                rel="noopener"
                aria-label={t("github")}
                size="large"
              >
                <GitHubIcon fontSize="medium" />
              </IconButton>
              <IconButton
                component={Link}
                href="mailto:amareteklay@gmail.com"
                aria-label={t("email")}
                size="large"
              >
                <EmailIcon fontSize="medium" />
              </IconButton>
            </Box>

            {/* Copyright */}
            <Typography
              variant="body2"
              sx={{
                color: "text.secondary",
                fontSize: "0.875rem",
                textAlign: "center",
                maxWidth: 600,
                lineHeight: 1.6,
              }}
            >
              {t("copyright", { year: new Date().getFullYear() })}
            </Typography>
          </Box>
        </Container>
      </Box>
    </Fade>
  );
};

export default Footer;
