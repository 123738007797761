import i18n from "i18next"
import { initReactI18next } from "react-i18next"

// Import translation files
import enCommon from "./locales/en/common.json"
import enNavbar from "./locales/en/navbar.json"
import enHero from "./locales/en/hero.json"
import enBlog from "./locales/en/blog.json"
import enFooter from "./locales/en/footer.json"
import enAbout from "./locales/en/about.json"
import enAboutSection from "./locales/en/aboutSection.json"
import enContactForm from "./locales/en/contactForm.json"
import enPortfolio from "./locales/en/portfolio.json"

import svCommon from "./locales/sv/common.json"
import svNavbar from "./locales/sv/navbar.json"
import svHero from "./locales/sv/hero.json"
import svBlog from "./locales/sv/blog.json"
import svFooter from "./locales/sv/footer.json"
import svAbout from "./locales/sv/about.json"
import svAboutSection from "./locales/sv/aboutSection.json"
import svContactForm from "./locales/sv/contactForm.json"
import svPortfolio from "./locales/sv/portfolio.json"

import tigCommon from "./locales/tig/common.json"
import tigNavbar from "./locales/tig/navbar.json"
import tigHero from "./locales/tig/hero.json"
import tigBlog from "./locales/tig/blog.json"
import tigFooter from "./locales/tig/footer.json"
import tigAbout from "./locales/tig/about.json"
import tigAboutSection from "./locales/tig/aboutSection.json"
import tigContactForm from "./locales/tig/contactForm.json"
import tigPortfolio from "./locales/tig/portfolio.json"

const resources = {
  en: {
    common: enCommon,
    navbar: enNavbar,
    hero: enHero,
    blog: enBlog,
    footer: enFooter,
    about: enAbout,
    aboutSection: enAboutSection,
    contactForm: enContactForm,
    portfolio: enPortfolio,
  },
  sv: {
    common: svCommon,
    navbar: svNavbar,
    hero: svHero,
    blog: svBlog,
    footer: svFooter,
    about: svAbout,
    aboutSection: svAboutSection,
    contactForm: svContactForm,
    portfolio: svPortfolio,
  },
  tig: {
    common: tigCommon,
    navbar: tigNavbar,
    hero: tigHero,
    blog: tigBlog,
    footer: tigFooter,
    about: tigAbout,
    aboutSection: tigAboutSection,
    contactForm: tigContactForm,
    portfolio: tigPortfolio,
  },
}

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  fallbackLng: "en",
  ns: ["common", "navbar", "hero", "footer", "about", "aboutSection", "portfolio"], // Use namespaces
  defaultNS: "common",
  interpolation: {
    escapeValue: false,
  },
})

export default i18n
