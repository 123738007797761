import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";

const About = () => {
  const { t } = useTranslation("about");
  const theme = useTheme();

  return (
    <Box sx={{ maxWidth: 900, mx: "auto", my: 6, px: 3, textAlign: "center" }}>
      {/* Title */}
      <Typography variant="h2" sx={{ 
            ...theme.mixins.gradientText,
            fontWeight: 800,
            mb: 2,
            fontSize: { xs: '2.5rem', md: '3.5rem' }
          }}>
        {t("title")}
      </Typography>

      {/* Introduction */}
      <Typography variant="h5" sx={{ fontWeight: 600, mb: 2 }}>
        {t("intro")}
      </Typography>

      {/* Detailed Bio */}
      <Typography variant="body1" sx={{ color: "text.secondary", lineHeight: 1.6 }}>
        {t("bio")}
      </Typography>

      {/* Professional Highlights */}
      <Box sx={{ mt: 5, textAlign: "left" }}>
        <Typography variant="h4" sx={{ fontWeight: 700, mb: 2 }}>
          {t("expertise_title")}
        </Typography>
        <Typography variant="body1" sx={{ color: "text.secondary", lineHeight: 1.6 }}>
          {t("expertise_details")}
        </Typography>
      </Box>

      {/* Mission & Future Goals */}
      <Box sx={{ mt: 5, textAlign: "left" }}>
        <Typography variant="h4" sx={{ fontWeight: 700, mb: 2 }}>
          {t("mission_title")}
        </Typography>
        <Typography variant="body1" sx={{ color: "text.secondary", lineHeight: 1.6 }}>
          {t("mission_details")}
        </Typography>
      </Box>

      {/* Personal Interests */}
      <Box sx={{ mt: 5, textAlign: "left" }}>
        <Typography variant="h4" sx={{ fontWeight: 700, mb: 2 }}>
          {t("hobbies_title")}
        </Typography>
        <Typography variant="body1" sx={{ color: "text.secondary", lineHeight: 1.6 }}>
          {t("hobbies_details")}
        </Typography>
      </Box>

      {/* Contact */}
      <Box sx={{ mt: 5 }}>
        <Typography variant="h4" sx={{ fontWeight: 700, mb: 2 }}>
          {t("contact_title")}
        </Typography>
        <Typography variant="body1" sx={{ color: "text.secondary", lineHeight: 1.6 }}>
          {t("contact_details")}
        </Typography>
      </Box>
    </Box>
  );
};

export default About;
