import React, { useState, useMemo } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import getTheme from './theme/theme';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import Home from './pages/Home';
import About from './pages/About';
import Portfolio from './pages/Portfolio';
import Blog from './pages/Blog';
import BlogDetail from './pages/BlogDetail';
import Contact from './pages/Contact';
// Import i18n configuration so that it initializes immediately
import './i18n';
import ProjectDetail from './pages/ProjectDetail';

function App() {
  // State for theme mode and language. Use language keys matching your i18n resources.
  const [mode, setMode] = useState('light');
  const [language, setLanguage] = useState('en');

  // Array of supported language keys
  const languages = ['en', 'sv', 'tig'];

  // Cycle through languages: en -> sv -> tig -> en ...
  const toggleLanguage = () => {
    setLanguage((prevLanguage) => {
      const currentIndex = languages.indexOf(prevLanguage);
      const nextIndex = (currentIndex + 1) % languages.length;
      const newLang = languages[nextIndex];
      // Update i18next with the new language
      import('i18next').then(({ default: i18n }) => {
        i18n.changeLanguage(newLang);
      });
      return newLang;
    });
  };

  const toggleColorMode = () => {
    setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
  };

  // Compute the theme based on the mode
  const theme = useMemo(() => getTheme(mode), [mode]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
          <Navbar 
            toggleColorMode={toggleColorMode} 
            mode={mode}
            toggleLanguage={toggleLanguage}
            language={language}
          />
          <main style={{ flex: 1, padding: '20px' }}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/portfolio" element={<Portfolio />} />
              <Route path="/projects/:id" element={<ProjectDetail />} />
              <Route path="/blog" element={<Blog />} />
              <Route path="/blog/:slug" element={<BlogDetail />} />
              <Route path="/contact" element={<Contact />} />
            </Routes>
          </main>
          <Footer />
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;
