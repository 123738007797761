import React from 'react';
import Hero from '../components/Hero/Hero';
import AboutSection from '../components/AboutSection/AboutSection';
import Portfolio from './Portfolio';
import Blog from './Blog';


const Home = () => {
  return (
    <div>
      <Hero />
      <AboutSection />
      <Portfolio />
      <Blog />
    </div>
  );
};

export default Home;
