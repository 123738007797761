import React, { useState, useEffect } from "react"
import {
  Box,
  Container,
  Grid,
  Card,
  CardContent,
  CardActions,
  Typography,
  Button,
  CircularProgress,
  useTheme,
  alpha,
  Fade,
} from "@mui/material"
import { Link as RouterLink } from "react-router-dom"
import { keyframes } from "@emotion/react"
import { useTranslation } from "react-i18next"
import WorkIcon from "@mui/icons-material/Work"
import CalendarTodayIcon from "@mui/icons-material/CalendarToday"
import PersonIcon from "@mui/icons-material/Person"

const floatAnim = keyframes`
  0% { transform: translateY(0px); }
  50% { transform: translateY(-15px); }
  100% { transform: translateY(0px); }
`

const Portfolio = () => {
  const theme = useTheme()
  const { t } = useTranslation("portfolio") // 🔹 Translation support
  const [projects, setProjects] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/api/projects/`)
      .then((res) => {
        if (!res.ok) {
          throw new Error("Failed to load projects.")
        }
        return res.json()
      })
      .then((data) => {
        setProjects(data)
        setLoading(false)
      })
      .catch((error) => {
        console.error("Error fetching projects:", error)
        setError(error.message)
        setLoading(false)
      })
  }, [])

  return (
    <Container maxWidth="lg" sx={{ py: 8 }}>
      <Fade in timeout={800}>
        <Box sx={{ textAlign: "center", mb: 8 }}>
          <Typography
            variant="h2"
            sx={{
              ...theme.mixins.gradientText,
              fontWeight: 800,
              mb: 2,
              fontSize: { xs: "2.5rem", md: "3.5rem" },
            }}
          >
            {t("title")}
          </Typography>
          <Typography
            variant="h6"
            sx={{
              color: "text.secondary",
              maxWidth: 600,
              mx: "auto",
            }}
          >
            {t("subtitle")}
          </Typography>
        </Box>
      </Fade>

      {loading ? (
        <Typography variant="body1" sx={{ textAlign: "center", width: "100%" }}>
          <CircularProgress sx={{ color: theme.palette.primary.main }} />
        </Typography>
      ) : error ? (
        <Typography variant="h5" color="error" sx={{ textAlign: "center" }}>
          {t("error")} {error}
        </Typography>
      ) : projects.length === 0 ? (
        <Typography variant="body1" sx={{ textAlign: "center", width: "100%" }}>
          {t("no_projects")}
        </Typography>
      ) : (
        <Grid container spacing={6}>
          {projects.map((project, index) => {
            const excerpt =
              project.description.length > 100
                ? `${project.description.substring(0, 100)}...`
                : project.description
            const date = new Date(project.date).toLocaleDateString()

            return (
              <Grid item xs={12} md={6} key={project.id}>
                <Fade
                  in
                  timeout={800}
                  style={{ transitionDelay: `${index * 100}ms` }}
                >
                  <Card
                    sx={{
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      borderRadius: "20px",
                      background: theme.palette.background.paper,
                      border: `1px solid ${theme.palette.divider}`,
                      transition: "transform 0.3s ease, box-shadow 0.3s ease",
                      "&:hover": {
                        transform: "translateY(-4px)",
                        boxShadow: theme.shadows[4],
                      },
                    }}
                  >
                    <Box
                      sx={{
                        height: 200,
                        bgcolor: alpha(theme.palette.primary.main, 0.1),
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        position: "relative",
                        overflow: "hidden",
                        "&::before": {
                          content: '""',
                          position: "absolute",
                          width: "200%",
                          height: "200%",
                          background: `conic-gradient(${alpha(
                            theme.palette.primary.main,
                            0.2
                          )} 20deg, transparent 120deg)`,
                          animation: `${floatAnim} 12s linear infinite`,
                        },
                      }}
                    >
                      <WorkIcon
                        sx={{
                          fontSize: 60,
                          background: `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.secondary.main} 90%)`,
                          WebkitBackgroundClip: "text",
                          WebkitTextFillColor: "transparent",
                          position: "relative",
                          zIndex: 1,
                        }}
                      />
                    </Box>

                    <CardContent sx={{ flexGrow: 1 }}>
                      <Typography
                        variant="h5"
                        sx={{ fontWeight: 700, mb: 2, lineHeight: 1.3 }}
                      >
                        {project.title}
                      </Typography>

                      <Typography
                        variant="body1"
                        sx={{ color: "text.secondary", mb: 3, lineHeight: 1.6 }}
                      >
                        {excerpt}
                      </Typography>

                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 3,
                          color: "text.secondary",
                        }}
                      ></Box>
                    </CardContent>

                    <CardActions sx={{ px: 3, pb: 3 }}>
                      <Button
                        component={RouterLink}
                        to={`/projects/${project.id}`}
                        variant="contained"
                        sx={{
                          px: 4,
                          borderRadius: theme.shape.buttonRadius,
                          background: `linear-gradient(45deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`,
                          "&:hover": {
                            transform: "translateY(-2px)",
                            boxShadow: `0 8px 24px ${alpha(
                              theme.palette.secondary.main,
                              0.3
                            )}`,
                          },
                          transition: "all 0.3s ease",
                        }}
                      >
                        {t("view_project")}
                      </Button>
                    </CardActions>
                  </Card>
                </Fade>
              </Grid>
            )
          })}
        </Grid>
      )}
    </Container>
  )
}

export default Portfolio
