import React from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  IconButton,
  useTheme,
  Slide,
  useScrollTrigger,
  Fade,
  Drawer,
  useMediaQuery,
} from "@mui/material";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import LanguageIcon from "@mui/icons-material/Language";
import MenuIcon from "@mui/icons-material/Menu";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

const languageNames = {
  en: "EN",
  sv: "SV",
  tig: "ትግ",
};

function HideOnScroll({ children }) {
  const trigger = useScrollTrigger();
  return <Slide appear={false} direction="down" in={!trigger}>{children}</Slide>;
}

const Navbar = ({ toggleColorMode, mode, toggleLanguage, language }) => {
  const { t } = useTranslation(["navbar", "common"]);
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const navItems = [
    { path: "/about", label: t("about", { ns: "navbar" }) },
    { path: "/portfolio", label: t("portfolio", { ns: "navbar" }) },
    { path: "/blog", label: t("blog", { ns: "navbar" }) },
    { path: "/contact", label: t("contact", { ns: "navbar" }) },
  ];

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <>
      <HideOnScroll>
        <AppBar
          position="sticky"
          sx={{
            backgroundColor: "background.paper",
            color: "text.primary",
            boxShadow: "none",
            borderBottom: `1px solid ${theme.palette.divider}`,
            "&::after": {
              content: '""',
              position: "absolute",
              bottom: 0,
              left: 0,
              right: 0,
              height: "2px",
              background: `linear-gradient(90deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`,
              opacity: 0.3,
            },
          }}
        >
          <Toolbar sx={{ py: 1.5 }}>
            {isMobile && (
              <IconButton
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 1 }}
              >
                <MenuIcon />
              </IconButton>
            )}

            <Typography
              variant={isMobile ? "h6" : "h3"}
              component={RouterLink}
              to="/"
              sx={{
                fontFamily: "'Tektur', cursive",
                fontWeight: 700,
                textDecoration: "none",
                color: "transparent",
                backgroundImage: "linear-gradient(45deg, #ff6600, #ffcc00, #ff6600)",
                backgroundSize: "200% 200%",
                WebkitBackgroundClip: "text",
                animation: "gradientAnimation 4s infinite alternate ease-in-out",
                transition: "transform 0.3s ease",
                fontSize: isMobile ? "1.5rem" : "inherit",
                "&:hover": {
                  transform: "scale(1.05)",
                },
                "@keyframes gradientAnimation": {
                  "0%": { backgroundPosition: "0% 50%" },
                  "100%": { backgroundPosition: "100% 50%" },
                },
              }}
            >
              {t("name", { ns: "common" })}
            </Typography>

            <Box sx={{ flexGrow: 1 }} />

            {!isMobile && (
              <Box sx={{ display: "flex", alignItems: "center", gap: 3, mr: 2 }}>
                {navItems.map((item) => (
                  <Fade key={item.path} in timeout={800}>
                    <Typography
                      component={RouterLink}
                      to={item.path}
                      variant="subtitle1"
                      sx={{
                        textDecoration: "none",
                        color: "inherit",
                        position: "relative",
                        "&::after": {
                          content: '""',
                          position: "absolute",
                          bottom: -2,
                          left: 0,
                          right: 0,
                          height: "2px",
                          backgroundColor: theme.palette.primary.main,
                          transform: "scaleX(0)",
                          transition: "transform 0.3s ease",
                        },
                        "&:hover::after": { transform: "scaleX(1)" },
                        "&.active::after": { transform: "scaleX(1)" },
                      }}
                    >
                      {item.label}
                    </Typography>
                  </Fade>
                ))}
              </Box>
            )}

            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <IconButton
                onClick={toggleLanguage}
                aria-label={t("toggle_language", { ns: "navbar" })}
                sx={{
                  color: "text.primary",
                  transition: "transform 0.3s ease",
                  "&:hover": {
                    transform: "translateY(-2px)",
                    color: theme.palette.primary.main,
                  },
                }}
              >
                <LanguageIcon />
                <Typography variant="caption" sx={{ ml: 0.5, fontWeight: 500 }}>
                  {languageNames[language]}
                </Typography>
              </IconButton>

              <IconButton
                onClick={toggleColorMode}
                aria-label={t("toggle_theme", { ns: "navbar" })}
                sx={{
                  color: "text.primary",
                  transition: "transform 0.3s ease",
                  "&:hover": {
                    transform: "rotate(15deg)",
                    color: theme.palette.primary.main,
                  },
                }}
              >
                {mode === "light" ? <Brightness4Icon /> : <Brightness7Icon />}
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>
      </HideOnScroll>

      <Drawer
        anchor="left"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        sx={{
          "& .MuiDrawer-paper": {
            width: 250,
            backgroundColor: theme.palette.background.paper,
          },
        }}
      >
        <Box sx={{ p: 2 }}>
          {navItems.map((item) => (
            <Typography
              key={item.path}
              component={RouterLink}
              to={item.path}
              variant="subtitle1"
              onClick={handleDrawerToggle}
              sx={{
                display: "block",
                py: 1.5,
                textDecoration: "none",
                color: "text.primary",
                "&:hover": {
                  color: theme.palette.primary.main,
                },
              }}
            >
              {item.label}
            </Typography>
          ))}
        </Box>
      </Drawer>
    </>
  );
};

export default Navbar;