import React from "react";
import { Box, Typography, Button, useTheme, alpha, Fade, Grid, Container } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { keyframes } from "@emotion/react";
import { useTranslation } from "react-i18next";
import meImage from "../../assets/images/me-sketch.jpeg";

const floating = keyframes`
  0% { transform: translateY(0px); }
  50% { transform: translateY(-20px); }
  100% { transform: translateY(0px); }
`;

const AboutSection = () => {
  const theme = useTheme();
  const { t } = useTranslation("aboutSection");

  return (
    <Fade in timeout={800}>
      <Box
        sx={{
          py: 10,
          px: 2,
          background: `linear-gradient(150deg, ${alpha(theme.palette.background.default, 0.8)} 0%, ${alpha(theme.palette.background.paper, 0.9)} 100%)`,
          position: "relative",
          overflow: "hidden",
        }}
      >
        {/* Animated background elements */}
        <Box
          sx={{
            position: "absolute",
            top: -100,
            right: -100,
            width: 400,
            height: 400,
            borderRadius: "50%",
            background: `radial-gradient(${alpha(theme.palette.primary.main, 0.1)} 30%, transparent 70%)`,
            animation: `${floating} 8s ease-in-out infinite`,
          }}
        />

        <Container maxWidth="lg">
          <Grid container spacing={6} alignItems="center">
            <Grid item xs={12} md={6}>
              <Typography
                variant="h2"
                sx={{
                  textAlign: "center",
                  mb: 8,
                  ...theme.mixins.gradientText, // Spread the mixin
                  fontWeight: 800,
                }}
              >
                {t("title")}
              </Typography>

              <Typography
                variant="body1"
                sx={{
                  fontSize: "1.1rem",
                  lineHeight: 1.8,
                  color: "text.secondary",
                  mb: 4,
                  position: "relative",
                  "&::before": {
                    content: '""',
                    position: "absolute",
                    left: "-24px",
                    top: 0,
                    height: "100%",
                    width: "3px",
                    background: theme.palette.secondary.main,
                    borderRadius: "2px",
                  },
                }}
              >
                {t("description")}
              </Typography>

              <Grid container spacing={3} sx={{ mb: 4 }}>
                <Grid item xs={6}>
                  <Typography variant="h4" sx={{ fontWeight: 700, color: "primary.main" }}>
                    {t("stats.projects_completed")}
                  </Typography>
                  <Typography variant="body2" sx={{ color: "text.secondary" }}>
                    {t("stats.projects_text")}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h4" sx={{ fontWeight: 700, color: "primary.main" }}>
                    {t("stats.research_papers")}
                  </Typography>
                  <Typography variant="body2" sx={{ color: "text.secondary" }}>
                    {t("stats.research_text")}
                  </Typography>
                </Grid>
              </Grid>

              <Button
                variant="contained"
                component={RouterLink}
                to="/about"
                size="large"
                sx={{
                  px: 5,
                  py: 1.5,
                  borderRadius: theme.shape.buttonRadius,
                  background: `linear-gradient(45deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`,
                  "&:hover": {
                    transform: "translateY(-2px)",
                    boxShadow: `0 8px 24px ${alpha(theme.palette.secondary.main, 0.3)}`,
                  },
                  transition: "all 0.4s ease",
                }}
              >
                {t("explore_button")}
              </Button>
            </Grid>

            <Grid item xs={12} md={6} sx={{ position: "relative" }}>
              <Box
                sx={{
                  background: `linear-gradient(45deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`,
                  borderRadius: theme.shape.cardRadius,
                  p: 0.5,
                  boxShadow: 3,
                  "&:hover img": {
                    transform: "scale(1.02)",
                  },
                }}
              >
                <Box
                  component="img"
                  src={meImage}
                  alt="Amare Teklay"
                  sx={{
                    width: "100%",
                    height: "auto",
                    borderRadius: theme.shape.cardRadius,
                    transition: "transform 0.4s ease",
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Fade>
  );
};

export default AboutSection;
