import React, { useState, useEffect } from "react";
import { useParams, Link as RouterLink } from "react-router-dom";
import {
  Container,
  Typography,
  CircularProgress,
  Box,
  IconButton,
  Divider,
} from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { useTheme, alpha } from "@mui/material/styles";

const BlogDetail = () => {
  const { slug } = useParams();
  const theme = useTheme();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch(`https://amareteklay.eu.pythonanywhere.com/api/blog/${slug}/`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch blog post.");
        }
        return response.json();
      })
      .then((data) => {
        setPost(data);
        setLoading(false);
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
  }, [slug]);

  if (loading) {
    return (
      <Container maxWidth="lg" sx={{ py: 8, textAlign: "center" }}>
        <CircularProgress />
      </Container>
    );
  }

  if (error) {
    return (
      <Container maxWidth="lg" sx={{ py: 8 }}>
        <Typography variant="h5" color="error">
          Error loading blog post: {error.message}
        </Typography>
      </Container>
    );
  }

  if (!post) {
    return (
      <Container maxWidth="lg" sx={{ py: 8 }}>
        <Typography variant="h5">No blog post found.</Typography>
      </Container>
    );
  }

  const publishedDate = new Date(post.created_at).toLocaleDateString();
  const authorName = post.author?.username
    ? post.author.username.charAt(0).toUpperCase() + post.author.username.slice(1)
    : "Unknown";

  return (
    <Container maxWidth="md" sx={{ py: 8 }}>
      <IconButton
        component={RouterLink}
        to="/blog"
        sx={{
          mb: 4,
          color: "text.secondary",
          "&:hover": {
            color: "primary.main",
            backgroundColor: alpha(theme.palette.primary.main, 0.1),
          },
        }}
      >
        <ArrowBack />
      </IconButton>

      <Typography
        variant="h1"
        sx={{
          fontWeight: 800,
          mb: 3,
          fontSize: { xs: "2.5rem", md: "3.5rem" },
          lineHeight: 1.2,
          background: `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.secondary.main} 90%)`,
          backgroundClip: "text",
          WebkitTextFillColor: "transparent",
        }}
      >
        {post.title}
      </Typography>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 2,
          mb: 6,
          "&::after": {
            content: "''",
            flex: 1,
            height: "1px",
            background: `linear-gradient(90deg, ${alpha(
              theme.palette.primary.main,
              0.3
            )} 0%, ${alpha(theme.palette.secondary.main, 0.3)} 100%)`,
          },
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 1.5 }}>
          <Typography
            variant="overline"
            sx={{ color: "text.secondary", letterSpacing: "0.1em", fontWeight: 600 }}
          >
            {publishedDate}
          </Typography>
          <Box
            sx={{
              width: 6,
              height: 6,
              borderRadius: "50%",
              bgcolor: "#ff6600",
              boxShadow: `0 0 8px ${alpha("#00ffd1", 0.5)}`,
            }}
          />
          <Typography
            variant="overline"
            sx={{ color: "text.primary", letterSpacing: "0.1em", fontWeight: 600 }}
          >
            {authorName}
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          position: "relative",
          "&::first-letter": {
            float: "left",
            fontSize: "4.5rem",
            lineHeight: 0.8,
            fontWeight: 700,
            mr: 2,
            background: `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.secondary.main} 90%)`,
            backgroundClip: "text",
            WebkitTextFillColor: "transparent",
          },
        }}
      >
        <Typography
          variant="body1"
          sx={{
            fontSize: "1.1rem",
            lineHeight: 1.8,
            fontFamily: '"Merriweather", serif',
            color: "text.primary",
            "& p": { mb: 3 },
            "& img": {
              width: "100%",
              borderRadius: 4,
              my: 4,
              boxShadow: theme.shadows[8],
            },
            "& blockquote": {
              borderLeft: `4px solid ${theme.palette.primary.main}`,
              pl: 3,
              my: 4,
              fontStyle: "italic",
              color: "text.secondary",
            },
          }}
        >
          {post.content}
        </Typography>
      </Box>

      <Divider
        sx={{
          my: 8,
          borderColor: alpha(theme.palette.divider, 0.3),
          borderWidth: 1,
        }}
      />

      {/* Add social sharing buttons or tags here */}
    </Container>
  );
};

export default BlogDetail;
