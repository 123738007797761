import React, { useState, useEffect } from "react";
import { useParams, Link as RouterLink } from "react-router-dom";
import {
  Container,
  Typography,
  Box,
  Button,
  CircularProgress,
  IconButton,
  useTheme,
  alpha,
} from "@mui/material";
import { ArrowBack } from "@mui/icons-material";

const ProjectDetail = () => {
  const { id } = useParams();
  const theme = useTheme();
  const [project, setProject] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/api/projects/${id}/`)
      .then((res) => {
        if (!res.ok) {
          throw new Error("Failed to load project details.");
        }
        return res.json();
      })
      .then((data) => {
        setProject(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching project details:", error);
        setError(error.message);
        setLoading(false);
      });
  }, [id]);

  if (loading) {
    return (
      <Container maxWidth="md" sx={{ py: 8, textAlign: "center" }}>
        <CircularProgress sx={{ color: theme.palette.primary.main }} />
      </Container>
    );
  }

  if (error) {
    return (
      <Container maxWidth="md" sx={{ py: 8, textAlign: "center" }}>
        <Typography variant="h5" color="error">{error}</Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="md" sx={{ py: 8 }}>
      <IconButton
        component={RouterLink}
        to="/portfolio"
        sx={{ mb: 4, color: "text.secondary", '&:hover': { color: 'primary.main' } }}
      >
        <ArrowBack />
      </IconButton>

      <Typography
        variant="h2"
        sx={{
          fontWeight: 800,
          mb: 3,
          fontSize: { xs: "2.5rem", md: "3.5rem" },
          background: `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.secondary.main} 90%)`,
          backgroundClip: "text",
          WebkitTextFillColor: "transparent",
        }}
      >
        {project.title}
      </Typography>

      {project.image && (
        <Box
          component="img"
          src={project.image}
          alt={project.title}
          sx={{
            width: "100%",
            borderRadius: 4,
            boxShadow: theme.shadows[8],
            mb: 4,
          }}
        />
      )}

      <Typography variant="body1" sx={{ fontSize: "1.1rem", lineHeight: 1.8, mb: 4 }}>
        {project.description}
      </Typography>

      <Button
        component={RouterLink}
        to={project.link}
        target="_blank"
        variant="contained"
        sx={{
          px: 4,
          background: `linear-gradient(45deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`,
          '&:hover': { boxShadow: `0 8px 24px ${alpha(theme.palette.secondary.main, 0.3)}` }
        }}
      >
        Visit Project
      </Button>
    </Container>
  );
};

export default ProjectDetail;
